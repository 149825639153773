<template>
  <div class="gamesScreen">
    <a v-for="projects in projectDataJSON.gameProjects" :key="projects.title"  class="showProjectBlock" :href=projects.url target="_blank" @mouseover="projects.showData = true" @mouseleave="projects.showData = false">
      <img class="projectImage" :src="projects.image" />
      <div class="projectTextHover" v-show="projects.showData">
        <h3 class="projectSubtitle">{{ projects.subTitle }}</h3>
      </div>
      <div class="projectTextBelow">
        <h2 class="projectTitle">{{ projects.title }}</h2>
        <p class="projectDescription">{{ projects.text }}</p>
      </div>
    </a>
  </div>
</template>

<script>
import projectDataJS from "@/data/projectData.json"
export default {
  data(){
    return{
      projectDataJSON: projectDataJS,
      showComponent: false
    }
  },
  mounted() {
  }
}
</script>

<style>
.gamesScreen{
  width: 95%;
  font-family: "Archivo Light";
  color: black;
  display: grid;
  height: 60vw;
  padding: 2.5vw 0 1vw 0;
  background-color: #eeeeee;
  border-radius: 0 0 1vw 1vw;
  grid-template-columns: 25vw 25vw 25vw;
  grid-template-rows: 30vw 30vw;
  justify-content: space-evenly;
}
.showProjectBlock{
  width: 100%;
  height: 65%;
  background-color: white;
  border-radius: 1vw;
  display: flex;
  flex-direction: column;
  position: relative;
}
.projectTextHover{
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgb(255,255,255,0.85);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 1vw;
  top: 0;
}
.projectTextBelow{
  position: absolute;
  width: 100%;
  top: 20vw;
  display: flex;
  justify-content: start;
  align-items: start;
  flex-direction: column;
  text-align: left;
}
.projectTitle{
  margin: 1vw 0 .5vw 1vw;
}
.projectSubtitle{
  margin: 0 0 1vw 0;
}
.projectDescription{
  width: 90%;
  margin: 0 0 0 1vw;
}
.projectImage{
  height: 100%;
  width: 100%;
  border-radius: 1vw;
  object-fit: cover;
}
</style>
