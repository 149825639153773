import { createRouter, createWebHistory } from 'vue-router'
import NotFound from '@/components/standardComponents/NotFound.vue';
import HomePage from "@/components/mainPages/HomePage.vue";

import WebtoolsPage from '@/components/mainPages/WebtoolsPage.vue';
import StyleMaker from '@/components/webTools/StyleMaker.vue';

import GamesPage from "@/components/mainPages/GamesPage.vue";

import AboutPage from "@/components/mainPages/AboutPage.vue";
import TermsOfService from "@/components/legal/TermsOfService.vue";
import PrivacyPolicy from "@/components/legal/PrivacyPolicy.vue";

import SupportPage from "@/components/mainPages/SupportPage.vue";
import TeachingPage from "@/components/mainPages/TeachingPage.vue";

const routes = [
    {
        path: '/',
        name: 'Home',
        component: HomePage
    },{
        path: '/games',
        name: 'Games',
        component: GamesPage
    },{
        path: '/teaching',
        name: 'Teaching',
        component: TeachingPage
    },{
        path: '/web',
        name: 'WebTools',
        component: WebtoolsPage
    },
    {
        path: '/web/stylemaker',
        name: 'StyleMaker',
        component: StyleMaker
    },{
        path: '/company',
        name: 'About',
        component: AboutPage
    },{
        path: '/company/terms-of-service',
        name: 'TermsOfService',
        component: TermsOfService
    },{
        path: '/company/privacy-policy',
        name: 'PrivacyPolicy',
        component: PrivacyPolicy
    },{
        path: '/support',
        name: 'SupportPage',
        component: SupportPage
    },
    {
        path: '/:catchAll(.*)',
        name: 'NotFound',
        component: NotFound,
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router