<template>
  <div class="fullnavbar">
    <router-link to="/">
      <img class="logo" v-bind:src="require('@/assets/LogoBlackWithText.png')" />
    </router-link>
    <div class="navLinksAll">
      <router-link to="/" class="navLink" :style="{backgroundColor: this.palette[0].hex}">
        <h3 class="navBarTitle">Home</h3>
      </router-link>
      <router-link to="/web" class="navLink" :style="{backgroundColor: this.palette[1].hex}">
        <h3 class="navBarTitle">Web</h3>
      </router-link>
      <router-link to="/games" class="navLink" :style="{backgroundColor: this.palette[2].hex}">
        <h3 class="navBarTitle">Games</h3>
      </router-link>
      <router-link to="/company" class="navLink" :style="{backgroundColor: this.palette[3].hex}">
        <h3 class="navBarTitle">About me</h3>
      </router-link>
      <router-link to="/support" class="navLink" :style="{backgroundColor: this.palette[4].hex}">
        <h3 class="navBarTitle">Support me</h3>
      </router-link>

<!--      <a  class="navLink" :style="{backgroundColor: '#b0e6ac'}" href="https://ko-fi.com/theprovisiongames" target="_blank" >-->
<!--        <h3>Support us</h3>-->
<!--      </a>-->
    </div>
  </div>
  <div class="indicatorArea">
    <div class="indicatorMargin">
      <div class="pageIndicator" :style="{backgroundColor: whichRoute === 'home' ? this.palette[0].hex : 'transparent'}"></div>
      <div class="pageIndicator" :style="{backgroundColor: whichRoute === 'web' ? this.palette[1].hex : 'transparent'}"></div>
      <div class="pageIndicator" :style="{backgroundColor: whichRoute === 'game' ? this.palette[2].hex : 'transparent'}"></div>
      <div class="pageIndicator" :style="{backgroundColor: whichRoute === 'company' ? this.palette[3].hex : 'transparent'}"></div>
      <div class="pageIndicator" :style="{backgroundColor: whichRoute === 'support' ? this.palette[4].hex : 'transparent'}"></div>
    </div>
  </div>
</template>

<script>
import paletteData from "@/data/colorPalette.json"
export default {
  name: 'ColorPicker',
  props: {
    change_route: Function
  },
  data(){
    return{
      whichRoute: 'home',
      palette: paletteData.palette
    }
  },
  watch:{
    '$route.path'(value) {
      if (value === '/') {
        this.whichRoute = 'home';
      } else if (value.startsWith('/game')) {
        this.whichRoute = 'game';
      } else if (value.startsWith('/teaching')) {
        this.whichRoute = 'teaching';
      } else if (value.startsWith('/web')) {
        this.whichRoute = 'web';
      } else if (value.startsWith('/company')) {
        this.whichRoute = 'company';
      } else if (value.startsWith('/support')) {
        this.whichRoute = 'support';
      } else {
        this.whichRoute = 'default';
      }
    }
  }
}
</script>

<style>
.fullnavbar{
  height: 5vw;
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-radius: 2vw 2vw 0 0;
  font-family: "Archivo Light";
}
.logo{
  height: 3vw;
  margin: 1.5vw 1.5vw 1.5vw 5vw;
}
.navLinksAll{
  display: flex;
  justify-content: flex-end;
  align-items: end;
  margin-right: 5vw;
}
.navLink{
  height: 83%;
  width: 15vw;
  color: white;
  border-radius: 1vw 1vw 0 0;
  //transform: skew(-25deg);
}
.pageIndicator{
  height: 1vw;
  width: 15vw;
  background-color: #b5e3a6;
  //transform: skew(-25deg);
}
.indicatorMargin{
  display: flex;
  margin-right: 2.55vw;
}
.indicatorArea{
  display: flex;
  justify-content: flex-end;
  align-items: end;
  height: 1vw;
  width: 95%;
  background-color: #eeeeee;
  border-radius: 1vw 1vw 0 0;
}
.navBarTitle{
  //transform: skew(25deg);
}
</style>