<template>
  <div class="gamesScreen" :style="{height: '28vw'}">
    <router-link v-for="project in projectDataJSON.webProjects" :key="project.title"  class="showProjectBlock" :to="project.url" @mouseover="project.showData = true" @mouseleave="project.showData = false">
      <img class="projectImage" v-bind:src=project.image />
      <div class="projectTextHover" v-show="project.showData">
      </div>
      <div class="projectTextBelow">
        <h2 class="projectTitle">{{ project.title }}</h2>
        <p class="projectDescription">{{ project.text }}</p>
      </div>
    </router-link>
  </div>
</template>

<script>
import projectDataJS from "@/data/projectData.json"
export default {
  data(){
    return{
      projectDataJSON: projectDataJS,
    }
  },
  mounted() {
  }
}
</script>

<style>

</style>