
<script>
import paletteData from "@/data/colorPalette.json"
export default {
  name: 'SupportPage',
  data(){
    return{
      palette: paletteData.palette
    }
  }
}
</script>

<template>
    <div class="homePage">
      <router-link to="/company" class="aboutCompanyBox" :style="{outline:`.5vw solid ${palette[0].hex}`}">
        <h1 class="subjectTitle">Studio Idem</h1>
        <p class="subjectDescription">A creative programming studio based in the Brainport region</p>
      </router-link>
      <div class="pages">
<!--        <router-link to="/web" class="subjectBox" :style="{outline:`.5vw solid ${palette[1].hex}`}">-->
<!--          <h1 class="subjectTitle">Web tools</h1>-->
<!--          <p class="subjectDescription">Making lesson material for students</p>-->
<!--        </router-link>-->
        <router-link to="/web" class="subjectBox" :style="{outline:`.5vw solid ${palette[2].hex}`}">
          <h1 class="subjectTitle">Web</h1>
          <p class="subjectDescription">Making web tools and websites</p>
        </router-link>
        <router-link to="/games" class="subjectBox" :style="{outline:`.5vw solid ${palette[3].hex}`}">
          <h1 class="subjectTitle">Games</h1>
          <p class="subjectDescription">Creating small games</p>
        </router-link>
      </div>
    </div>
</template>

<style>
.homePage {
  width: 95%;
  font-family: "Archivo Light";
  height: 29vw;
  background-color: #eeeeee;
  border-radius: 0 0 1vw 1vw;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pages{
  display: grid;
  grid-template-columns: 57% 57%;
  gap: 3vw;
  justify-content: center;
}
.aboutCompanyBox{
  margin: 1vw 0 1vw 0;
  border-radius: 1vw;
  background-color: white;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.subjectBox{
  margin: 2vw 0 2vw 0;
  border-radius: 1vw;
  background-color: white;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.subjectTitle{
  margin: 2vw 0 1.5vw 0;
}
.subjectDescription{
  width: 75%;
  font-size: 1.5vw;
}
</style>