<template>
  <div class="wholeSite">
    <NavBar/>
    <router-view/>
    <BottomBar/>
  </div>
  <CookieModal v-if="showModal" @closeCookies="handleModalClose" @acceptCookies="handleCookieAccept" @declineCookies="handleCookieDecline"/>
</template>

<script>
import NavBar from "@/components/standardComponents/NavBar.vue";
import BottomBar from "@/components/standardComponents/BottomBar.vue"
import CookieModal from "@/components/standardComponents/CookieModal.vue";
import Cookies from 'js-cookie'

export default {
  name: 'App',
  data(){
    return{
      backgroundColorNow: '#8582d9',
      showModal: true,
    }
  },
  components: {
    NavBar,
    BottomBar,
    CookieModal,
  },
  methods: {
    handleCookieAccept(){
      Cookies.set('cookieConsent', 'accepted', { expires: 365 })
    },
    handleCookieDecline(){
      Cookies.set('cookieConsent', 'declined', { expires: 365 })
    },
    handleModalClose() {
      this.showModal = false;
    }
  },
  watch:{
    '$route' (){
      switch(this.$route.path){
        case '/':
          this.backgroundColorNow = '#2e424d'
          break;
        case '/StyleMaker':
            this.backgroundColorNow = '#dddddd';
          break;
        default:
          this.backgroundColorNow = '#4d6f80'
      }
    }
  },
  mounted() {
    if(Cookies.get('cookieConsent', 'accepted') || Cookies.get('cookieConsent', 'declined') ){
      this.showModal = false
    }
  }
}
</script>

<script setup>
</script>

<style>
body{
  margin: 0;
}
.wholeSite{
  width: 99vw;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}
h1 {
  font-size: 2vw;
}
h2 {
  font-size: 1.5vw;
}
h3 {
  font-size: 1.4vw;
}
p {
  margin-top: 0.5vw;
  font-size: 1vw;
}
button {
  font-size: 1vw;
  border-radius: 2vw;
  height: 2.5vw;
  width: 7.5vw;
  text-align: center;
  border: none;
  color: white;
  font-family: "Questrial";
  background-color: grey;
}
button:hover {
  cursor: pointer;
}
select {
  font-size: 1vw;
  border-radius: .2vw;
  border: none;
  height: 2.5vw;
  text-align: center;
  background-color: white;
  color: black;
  font-family: "Questrial";
}
select:hover {
  cursor: pointer;
}
select:focus {
  color: white;
}
input {
  font-size: 1vw;
  border-radius: .1vw;
  height: 2vw;
  text-align: center;
  background-color: white;
  border: .2vw solid #333015;
  color: grey;
  font-family: "Questrial";
}
#app {
  text-align: center;
}
a {
  font-size: 1.2vw;
  font-family: "Archivo ExtraLight";
  text-decoration: none;
  color: black;
}
</style>
