<script>
import paletteData from "@/data/colorPalette.json"
export default {
  name: 'SupportPage',
  data(){
    return{
      palette: paletteData.palette
    }
  }
}
</script>

<template>
  <div class="aboutScreen">
    <div class="aboutMe">
      <div class="aboutContentHolder">
        <p class="aboutText">
          I created Studio Idem as a platform to share my creations with the world. The projects that I do are in the scope of game- and web design. Every project has its own purpose. Game projects are mostly for fun, while the web projects are tools that I think are useful for myself and other designers.
        </p>
        <p class="aboutText" :style="{margin: '1vw 0 1vw 3vw'}">
          The design and implementation are all done by myself since I like the variety of designing and programming. I'm always open for freelance projects or just fun projects to work on for my own portfolio. If you have any suggestions, send me a dm on Twitter.
        </p>
        <a :style="{margin: '0 0 2vw 0', backgroundColor: palette[1].hex}" class="socialMediaIconCircle" href='https://twitter.com/studio_idem' target='_blank'>
          <img src='@/assets/SocialMedia/TwitterLogo.png' alt="X/twitter logo" class="socialMediaIcon">
        </a>
      </div>
      <div class="aboutPerson">
        <img src='@/assets/profilePic.jpeg' alt="profile picture" class="aboutPhoto">
        <div :style="{textAlign: 'left'}">
          <h3 :style="{margin: '.5vw 0 .25vw 1.5vw'}">Lars Hak</h3>
          <p :style="{margin: '0 0 0 1.5vw'}">Owner of Studio Idem</p>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.aboutScreen{
  width: 95%;
  display: flex;
  justify-content: center;
  font-family: "Archivo Light";
  height: 30vw;
  background-color: #eeeeee;
  border-radius: 0 0 1vw 1vw;
}
.aboutMe{
  margin: 3vw 0 0 0;
  width: 70%;
  background-color: white;
  border-radius: 1vw;
  height: 24vw;
  display: grid;
  grid-template-columns: 60% 35%;
  gap: 1vw;
}
.aboutContentHolder{
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  margin-left: 1.5vw;
}
.aboutText{
  margin: 2vw 0 0 3vw;
  text-align: start;
}
.aboutPhoto{
  width: 16vw;
  height: 16vw;
  margin: 2.75vw 0 0 1.5vw;
  object-fit: cover;
  object-position: 0 10%;
}
.aboutPerson{
  display: flex;
  flex-direction: column;
  justify-content: start;
  margin-left: 2.25vw;
}
</style>